import fastdom from 'fastdom'
import fastdomPromised from 'fastdom/extensions/fastdom-promised'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const fd = fastdom.extend(fastdomPromised)

const setupNavigation = (el) => {
  const openBtn = el.querySelector('.js-open')
  const closeBtn = el.querySelector('.js-close')

  function open() {
    fd.mutate(() => {
      disableBodyScroll(el)
      el.classList.add('is-open')
    })
  }

  function close() {
    fd.mutate(() => {
      enableBodyScroll(el)
      el.classList.remove('is-open')
    })
  }

  function init() {
    openBtn.addEventListener('click', open, false)
    closeBtn.addEventListener('click', close, false)
  }

  init(el)
}

export default setupNavigation
