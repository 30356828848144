import Highway from '@dogstudio/highway'
import anime from 'animejs/lib/anime.es.js'
import loadModules from './loadModules'

const setupTransitions = () => {
  class defaultRenderer extends Highway.Renderer {
    onEnter() {
      loadModules(this.wrap.lastElementChild)
    }
  }

  function reloadLogo() {
    const logo = document.querySelector('.js-logo')
    const src = logo.src

    window.requestAnimationFrame(() => {
      logo.setAttribute('src', src)
    })
  }

  class defaultTransition extends Highway.Transition {
    in({ from, to, trigger, done }) {

      from.remove()
      reloadLogo()

      anime({
        targets: '.js-overlay',
        translateX: '100%',
        easing: 'easeOutQuad',
        duration: 750,
        complete: () => {
          anime({
            targets: '.js-overlay',
            translateX: '-100%',
            duration: 0,
            complete: done,
          })

          if (window.CookieConsent) {
            window.CookieConsent.runScripts()
          }
        },
      })
    }

    out({ from, to, trigger, done }) {
      anime({
        targets: '.js-overlay',
        translateX: ['-100%', '0%'],
        easing: 'easeInQuad',
        duration: 750,
        complete: () => {
          window.scrollTo(0, 0)
          document.querySelector('.js-navigation').classList.remove('is-open')
          window.setTimeout(() => {
            done()
          }, 400)
        },
      })
    }
  }

  const H = new Highway.Core({
    renderers: {
      default: defaultRenderer,
    },
    transitions: {
      default: defaultTransition,
    },
  })
}

export default setupTransitions
