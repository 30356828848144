export default (page) => {
  const isTrack = page.classList.contains('js-track')
  const modalContainer = page.querySelector('.js-modals') || null

  if (isTrack) {
    import(/* webpackChunkName: "track" */ '../modules/setupTrack').then(
      ({ default: setupTrack }) => {
        setupTrack(page)
      }
    )
  }

  if (modalContainer !== null) {
    import(/* webpackChunkName: "modals" */ '../modules/setupModals').then(
      ({ default: setupModals }) => {
        setupModals(modalContainer)
      }
    )
  }
}
